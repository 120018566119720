<!--
 * @Description: 工作台首页
 * @Author: zhang zhen
 * @Date: 2023-02-13 16:08:54
 * @LastEditors: zhang zhen
 * @LastEditTime: 2024-05-14 16:52:57
 * @FilePath: /page-sass/src/views/workSpace/index.vue
-->
<template>
  <div class="workSpace">
    <div class="left-info">
      <div class="topView">
        <div class="topView-item">
          <img src="~@/assets/queryPrice.png" alt class="icon" />
          <div class="right-view">
            <div class="title">询报价次数</div>
            <div class="number">373.5w+</div>
          </div>
        </div>
        <div class="topView-item">
          <img src="~@/assets/orderNum.png" alt class="icon" />
          <div class="right-view">
            <div class="title">下接单次数</div>
            <div class="number">0</div>
          </div>
        </div>
        <div class="topView-item">
          <img src="~@/assets/send.png" alt class="icon" />
          <div class="right-view">
            <div class="title">已收发货次数</div>
            <div class="number">0</div>
          </div>
        </div>
        <div class="topView-item">
          <img src="~@/assets/QrNumber.png" alt class="icon" />
          <div class="right-view">
            <div class="title">质量投诉次数</div>
            <div class="number">0</div>
          </div>
        </div>
      </div>
      <a-tabs v-model="activeIndex">
        <a-tab-pane key="1" tab="需求动态">
          <a-tabs v-model="businessType" type="card" @change="handleChangeCategory">
            <!-- <a-tab-pane key tab="全部"></a-tab-pane> -->
            <a-tab-pane key="0" tab="供应商"></a-tab-pane>
            <!-- <a-tab-pane key="1" tab="包装"></a-tab-pane>
            <a-tab-pane key="2" tab="方案"></a-tab-pane> -->
          </a-tabs>
          <a-table
            :columns="columns"
            :loading="loading"
            :data-source="requirementList"
            :pagination="false"
            style="margin-bottom: 16px;"
          >
            <template slot="status" slot-scope="text, record">
              <a-badge color="#5E5E66" text="已取消" v-if="text == 0" />
              <a-badge status="processing" text="已发布" v-if="text == 1" />
              <a-badge status="success" text="已完成" v-if="text == 2" />
              <a-badge color="#36C290" text="待发布" v-if="text == 3" />
              <a-badge status="error" text="已删除" v-if="text == -1" />
              <a-badge status="error" text="待审核" v-if="text == 4" />
              <a-badge color="#36C290" text="已驳回" v-if="text == 5" />
            </template>
            <template slot="createTime" slot-scope="text, record">
              <span>{{ text | formatDate }}</span>
            </template>
            <template slot="operation" slot-scope="text, record">
              <a @click="handleSwitchTab(record)">详情</a>
            </template>
          </a-table>
          <ZPagination
            :total="pageNation.total"
            :current="pageNation.pageNum"
            :pageSize="pageNation.pageSize"
            @handlePageChange="handleChangePage"
          />
        </a-tab-pane>
        <a-tab-pane key="2" tab="订单动态">
          <a-table
            :columns="columnsA"
            :loading="loadingA"
            :data-source="orderList"
            :pagination="false"
            style="margin-bottom: 16px;"
          >
            <template slot="status" slot-scope="text, record">
              <a-badge color="#1890FF" text="待签署" v-if="text == 0" />
              <a-badge color="#FF6E2D" text="已签署" v-if="text == 1" />
              <a-badge color="#FF6E2D" text="已预付" v-if="text == 2" />
              <a-badge color="#1890FF" text="生产中" v-if="text == 3" />
              <a-badge color="#FF6E2D" text="已发货" v-if="text == 4" />
              <a-badge color="#FF6E2D" text="运输中" v-if="text == 5" />
              <a-badge color="#FF6E2D" text="已收货" v-if="text == 6" />
              <a-badge color="#52C41A" text="已完结" v-if="text == 7" />
              <a-badge color="#FF6E2D" text="已付款" v-if="text == 9" />
              <a-badge color="#D9D9D9" text="已取消" v-if="text == -1" />
            </template>
            <template slot="operation" slot-scope="text, record">
              <a @click="handleSwitchTabB(record)">详情</a>
            </template>
          </a-table>
          <ZPagination
            :total="pageNationA.total"
            :current="pageNationA.pageNum"
            :pageSize="pageNationA.pageSize"
            @handlePageChange="handleChangePageA"
          />
        </a-tab-pane>
      </a-tabs>
    </div>
    <div class="right-info">
      <div class="message-box">
        <div class="userInfo">
          <div class="userInfo-left">
            <a-avatar :src="setUserInfo().avatar || '/avatar.png'" style="background-color: #fff" />
            <div class="infoBox">
              <span class="memo">Hi! 你好~</span>
              <h3 class="userName">用户昵称{{ setUserInfo().nickname || '' }}</h3>
            </div>
          </div>
          <div class="editUser" @click="handleImprovingUserInfo" v-show="setUserInfo().status != 1">
            <img src="~@/assets/home/editnew.png" alt />
            <span>完善资料</span>
          </div>
        </div>
        <div class="tags-list">
          <template v-for="(item, index) in tagList">
            <a-tag class="normalTag">{{ item.dictValue }}</a-tag>
          </template>
          <a-tooltip slot="suffix" :title="memoInfo" v-if="memoInfo">
            <a-tag class="normalTag moreTag">...</a-tag>
          </a-tooltip>
          <template v-if="tagList.length">
            <a class="tagEdit" v-if="!isEdit" @click="handleImprovingUserInfo">编辑</a>
            <a class="tagEdit" v-else @click="isEdit = false">保存</a>
          </template>
        </div>
      </div>
      <div class="notice-info">
        <div class="notice-info-header">通知</div>
        <div class="content">
          <a-tabs default-active-key="1" @change="handleChangeMessageTab" size="small">
            <a-tab-pane key="1" tab="个人信息">
              <div class="messageBox-item" v-for="i in 2" :key="i">【消息通知】订单已经发货</div>
            </a-tab-pane>
            <a-tab-pane key="2" tab="官方公告">
              <div
                class="messageBox-item"
                v-for="i in officeList"
                :key="i.noticeId"
              >【公共】{{ i.title }}</div>
            </a-tab-pane>
          </a-tabs>
        </div>
        <!-- lineHeader.jpg -->
      </div>
    </div>
  </div>
</template>

<script>
import InfoCardItem from './modules/infoCardItem'
import OrderCardItem from './modules/orderCardItem'
import { mapGetters } from 'vuex'
import { getAction, postAction } from '@/api/manage'
import EmptyArea from '@/components/plugins/EmptyArea.vue'
import ZPagination from '@/components/plugins/ZPagination.vue'

export default {
  name: 'workSpace',
  components: {
    InfoCardItem,
    OrderCardItem,
    EmptyArea,
    ZPagination
  },
  data() {
    return {
      loadingA: false,
      tagList: [],
      inputVisible: false,
      inputValue: '',
      loading: false,
      officeList: [],
      orderList: [], // 订单列表
      requirementList: [], // 需求列表
      businessType: '0',
      activeIndex: '1',
      pageNation: {
        pageNum: 1,
        pageSize: 20,
        total: 0
      },
      pageNationA: {
        pageNum: 1,
        pageSize: 20,
        total: 0
      },
      columns: [
        {
          title: '需求名称',
          dataIndex: 'purchaseTitle',
          key: 'purchaseTitle'
        },
        {
          title: '需求编号',
          dataIndex: 'purchaseNo',
          key: 'purchaseNo'
        },
        {
          title: '需求状态',
          dataIndex: 'status',
          key: 'status',
          scopedSlots: { customRender: 'status' }
        },
        {
          title: '供应商参与人数',
          dataIndex: 'participantCount',
          key: 'participantCount'
        },
        {
          title: '提交时间',
          dataIndex: 'createTime',
          key: 'createTime',
          scopedSlots: { customRender: 'createTime' }
        },
        {
          title: '操作',
          dataIndex: 'operation',
          scopedSlots: { customRender: 'operation' }
        }
      ],
      columnsA: [
        {
          title: '需求名称',
          dataIndex: 'orderTitle',
          key: 'orderTitle'
        },
        {
          title: '订单编号',
          dataIndex: 'orderNo',
          key: 'orderNo'
        },
        {
          title: '状态',
          dataIndex: 'status',
          key: 'status',
          scopedSlots: { customRender: 'status' }
        },
        {
          title: '提交时间',
          dataIndex: 'createTime',
          key: 'createTime',
          scopedSlots: { customRender: 'createTime' }
        },
        {
          title: '操作',
          dataIndex: 'operation',
          with: 150,
          scopedSlots: { customRender: 'operation' }
        }
      ],
      memoInfo: null,
      isEdit: false
    }
  },
  created() {
    this.handleLoadNotice()
    this.handleLoadUserTag()
    this.handleLoadRequireList() // 加载需求数据
    this.handleLoadOrder()
  },
  methods: {
    ...mapGetters(['setUserInfo']),
    handleChangeCategory() {
      this.handleLoadRequireList()
    },
    /* 获取用户标签 */
    handleLoadUserTag() {
      getAction('/tag/BusinessTagQuery', { userId: this.setUserInfo().userId }).then((res) => {
        const { success, data } = res
        if (success && data) {
          // this.tagList = data.businessLists || []
          let labelArr = []
          if (!data && !data.businessLists) return
          data.businessLists.map((i, index) => {
            if (index >= 4) {
              labelArr.push(i.dictValue)
            } else {
              this.tagList.push(i)
            }
          })
          this.memoInfo = labelArr.length ? labelArr.join('、') : null
        }
      })
    },
    /* 删除标签 */
    handleClose({ tagId }) {
      postAction('/tag/user/delete', { tagId }).then(res => {
        const { success, message } = res
        if (success) {
          this.$message.success(message)
          this.handleLoadUserTag()
        } else {
          this.$message.warning(message)
        }
      })
    },
    handleSwitchTabB(record) {
      const { orderTitle, orderNo } = record
      console.log(this.setUserInfo().tradeIdentity == 0 || this.setUserInfo().tradeIdentity == 1)
      this.$router.push(
        this.setUserInfo().tradeIdentity == 0 || this.setUserInfo().tradeIdentity == 1
          ? `/orderManagement/orderDetails?orderTitle=${orderTitle}&orderNo=${orderNo}`
          : `/orderManagement/orderDetailsForUser?orderTitle=${orderTitle}&orderNo=${orderNo}`
      )
    },
    handleSwitchTab(item) {
      const { purchaseId, purchaseTitle, purchaseNo, businesStatus, status } = item
      this.$router.push(
        `/requirement/ForUserAndPurchaseDetails?purchaseId=${purchaseId}&purchaseTitle=${purchaseTitle}&purchaseNo=${purchaseNo}&activeTab=BasicInfo&formType=${
          businesStatus == '0' ? 'product' : businesStatus == '1' ? 'package' : 'plan'
        }&status=${status}`
      )
    },
    handleLoadRequireList() {
      const { pageNum, pageSize } = this.pageNation
      this.loading = true
      this.requirementList = []
      postAction('/purchase/pool/publish/query', {
        status: [],
        businessType: this.businessType,
        pageNum,
        pagesSize: pageSize,
        businessId: this.setUserInfo().businessId || this.setUserInfo().userId
      }).then(res => {
        const { success, data } = res
        this.loading = false
        if (success) {
          const { total, list } = data
          this.pageNation.total = total
          this.requirementList = list
        }
      })
    },
    handleLoadOrder() {
      const { pageNum, pageSize } = this.pageNation
      this.loadingA = true
      // 加载订单数据
      postAction('/order/progress', {
        status: [],
        pageNum,
        pagesSize: pageSize
      }).then(res => {
        const { success, data } = res
        this.loadingA = false
        if (success) {
          this.orderList = data.list || []
          this.pageNationA.total = data.total
        }
      })
    },
    handleChangePage({ pageNum, pageSize }) {
      this.pageNation.pageNum = pageNum
      this.pageNation.pageSize = pageSize
      this.handleLoadRequireList()
    },
    handleChangePageA({ pageNum, pageSize }) {
      this.pageNationA.pageNum = pageNum
      this.pageNationA.pageSize = pageSize
      this.handleLoadOrder()
    },
    handleLoadNotice() {
      getAction('/notice/list').then(res => {
        const { success, data } = res
        success && (this.officeList = data)
      })
      getAction('/remind/msg/new').then(res => {
        const {} = res
      })
    },
    showInput() {
      this.inputVisible = true
      this.$nextTick(function() {
        this.$refs.input.focus()
      })
    },

    handleInputChange(e) {
      this.inputValue = e.target.value
    },

    handleInputConfirm() {
      if (!this.inputValue) return this.$message.warning('标签名称不能为空')
      postAction('/tag/user/add', {
        userId: this.setUserInfo().userId,
        tagName: this.inputValue
      }).then(res => {
        const { success, message } = res
        if (success) {
          this.$message.success(message)
          Object.assign(this, {
            inputVisible: false,
            inputValue: ''
          })
          this.handleLoadUserTag()
        } else {
          this.$message.warning(message)
        }
      })
    },
    handleImprovingUserInfo() {
      // this.$router.push('/ImproveInformation')
      this.$router.push('/businessManagement/companyInfo')
    },
    handleChangeMessageTab(e) {
      // 消息接口对接
    }
  }
}
</script>

<style lang="less" scoped>
.workSpace {
  display: flex;
  grid-gap: 25px 25px;
  min-height: calc(100vh - 90px);
  background: #fff;
  padding: 12px;
  box-sizing: border-box;
  .left-info {
    flex: 1 0 0;
    min-width: 0;
    .topView {
      min-height: 90px;
      display: flex;
      justify-content: space-between;
      grid-gap: 30px 30px;
      margin-bottom: 15px;
      flex-wrap: wrap;
      &-item {
        width: calc((100% - 90px) / 4);
        height: 90px;
        background-color: #f7f8fa;
        border-radius: 8px;
        padding: 15px;
        display: flex;
        align-items: center;
        img.icon {
          width: 64px;
          height: 64px;
          margin-right: 6px;
        }
        .right-view {
          font-size: 12px;
          color: #1d2129;
          line-height: 19px;
          .number {
            font-size: 21px;
            line-height: 30px;
            font-weight: 500;
          }
        }
      }
    }
    ::v-deep .ant-table {
      color: rgba(0, 0, 0, 0.85);
    }
    ::v-deep .ant-table-column-title {
      font-size: 16px;
    }
    ::v-deep .ant-table-thead {
      tr {
        background: #efefef;
        th {
          border: none;
        }
      }
    }
    ::v-deep .ant-table-tbody {
      tr {
        &:not(:last-of-type) > td {
          border: none;
        }
        & > td {
          border-bottom: 1px solid #EFEFEF;
        }
      }
    }
    ::v-deep .ant-table-thead > tr > th {
      background: #F4F5F7;
      border-bottom: none;
      font-weight: 500;
    }
  }
  .right-info {
    width: 242px;
    .message-box {
      min-height: 136px;
      background: url('~@/assets/userBg.png') no-repeat;
      background-size: 100% 100%;
      padding: 15px;
      margin-bottom: 20px;
      .userInfo {
        position: relative;
        .editUser {
          display: flex;
          align-items: center;
          color: #ff6e2d;
          position: absolute;
          cursor: pointer;
          top: 0;
          right: 0;
          img {
            width: 16px;
            height: 16px;
            margin-right: 4px;
          }
        }
        &-left {
          display: flex;
          .ant-avatar {
            width: 44px;
            height: 44px;
          }
          .infoBox {
            margin-left: 8px;
            font-size: 12px;
            color: #595959;
            h3.userName {
              color: #595959;
              font-size: 12px;
              font-weight: 400;
            }
          }
        }
      }
    }
  }
}
.tags-list {
  width: 100%;
  margin-top: 10px;
  .ant-tag {
    margin-bottom: 6px;
    background-color: #fff;
    .anticon-close {
      opacity: 0;
    }
    &:hover {
      .anticon-close {
        opacity: 1;
      }
    }
  }
  .ant-tag-blue {
    color: #ff6e2d;
    border-color: #ff6e2d;
  }
}
::v-deep .ant-badge-status-dot {
  width: 8px;
  height: 8px;
}

.notice-info {
  border-radius: 8px;
  border: 1px solid #ededf0;
  overflow: hidden;
  &-header {
    height: 38px;
    background: url('~@/assets/lineHeader.jpg') no-repeat;
    background-size: 100% 100%;
    padding: 0 30px;
    color: #000;
    font-weight: 500;
    line-height: 38px;
  }
  .content {
    padding: 20px 15px;
    ::v-deep .ant-tabs-small .ant-tabs-tab {
      font-size: 12px !important;
    }
    .messageBox-item {
      font-size: 12px;
      color: #131212;
      line-height: 22px;
    }
  }
}

::v-deep .ant-tag {
  height: 24px;
  line-height: 24px;
  cursor: pointer;
  font-size: 12px;
  margin-right: 6px;
  &.normalTag {
    background: #fff;
    border: none;
    color: #595959;
  }
  &.moreTag {
    &:hover {
      color: #ff6e2d;
    }
  }
}

a.tagEdit {
  font-size: 12px;
  display: inline-block;
  position: relative;
  padding-left: 8px;
  &::before {
    display: inline-block;
    content: '';
    width: 1px;
    height: 14px;
    background: #ece7e7;
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
  }
}
</style>
